import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestPlanSuggestions = () => {
  return (
    <LayoutComponent>
      <h2>Test Plan Suggestions</h2>
      <p>
        The Test Plan Suggestions feature helps streamline your testing process
        by generating a tailored test plan using intelligent filtering and
        analysis. This ensures that your test cycle focuses on the most critical
        and relevant test cases, saving you time and effort.
      </p>

      <h4>How It Works</h4>
      <ol>
        <li>
          <strong>Select Modules</strong>: Choose the modules that represent the
          components or features of your application that need testing. These
          modules help scope your test plan to specific areas of interest.
        </li>
        <li>
          <strong>Apply Filters</strong>: Refine the test cases by filtering
          based on the priority of linked issues. This allows you to prioritize
          test cases associated with high-priority or critical issues to ensure
          key project areas are covered.
        </li>
        <li>
          <strong>Review Smart Suggestions</strong>: Based on your selections
          and filters, the system will suggest a list of relevant test cases.
          Each suggestion includes:
          <ul className="m-0">
            <li>Automation coverage</li>
            <li>Linked issue priorities</li>
            <li>Test case status</li>
            <li>Associated module details</li>
          </ul>
        </li>
        <li>
          <strong>Filter Test Cases</strong>: Use additional filters to refine
          the list further:
          <ul className="m-0">
            <li>Show only automated test cases</li>
            <li>Filter test cases by module</li>
          </ul>
        </li>
        <li>
          <strong>Select and Create Test Cycle</strong>: Once you’ve finalized
          your test cases, select the ones you wish to include and proceed to
          create a test cycle. You’ll need to provide the following details:
          <ul className="m-0">
            <li>
              <strong>Name</strong>: A descriptive name for the test cycle.
            </li>
            <li>
              <strong>Purpose</strong>: Specify the type of test cycle, such as
              regression, sanity, or UAT.
            </li>
            <li>
              <strong>Type</strong>: select whether the test cycle is manual or
              automated.
            </li>
          </ul>
        </li>
      </ol>

      <h4>Benefits</h4>
      <ul className="m-0">
        <li>Save time by leveraging intelligent test case suggestions.</li>
        <li>Ensure critical areas of your project are adequately tested.</li>
        <li>Quickly create comprehensive and focused test cycles.</li>
      </ul>

      <h4>Getting Started</h4>
      <p>
        To use this feature, navigate to the{" "}
        <strong>Test Plan Suggestions</strong> section, select your desired
        modules and issue priorities, and review the suggested test cases. Once
        satisfied, proceed to create a tailored test cycle to start execution.
      </p>
    </LayoutComponent>
  )
}

export default TestPlanSuggestions
